:root {
  /* Colors: */
  --blonde-green: #21A685;
  --awaken-my-love-blue: #36BDD9;
  --crazysexycool-orange: #E65845;
  --cool-neutral: #EDEAE6;
  --blkswn-rose: #DF195D;
  --aluminum: #333333;
  --carbon: #272727;
  --black-carbon: #161616;
  --forest-hills-drive: #034034;
  --s-o-s-navy: #091D2B;
  --faith-blue: #07314D;
  --deep-depression-cherry: #2B060F;
  --white: #FFFFFF;
  --warm-neutral: #E6E0D8;
  }

html, body {
  margin: 0;
  background: var(--black-carbon);
  color: var(--white);
  font-family: 'Arboria', 'Arial',
    sans-serif;
  font-size: 30.6px;
}

::selection {
  background: var(--blonde-green);
}

::-webkit-selection {
  background: var(--blonde-green);
}

::-moz-selection {
  background: var(--blonde-green);
}

@media screen and (max-width: 1370px) {
  html, body {
    font-size: calc(34px * 0.7639);
  }
}
@media screen and (max-width: 1205px) {
  html, body {
    font-size: 21px;
  }
}
@media screen and (max-width: 1024px) {
  html, body {
    font-size: 18px;
  }
}
@media screen and (max-width: 195px) {
  html, body {
    font-size: 15px;
  }
}